<template>
  <div class="dynamic">
    <div class="dynamic-container">
    <div class="industryTrends">
      <div class="industryTrends-title">行业动态</div>
      <div class="industryTrends-contentBoxList">
        <div class="itemBox">
          <div class="contentList-left">
            <div
              class="itemLeft"
              v-for="(item, index) in newItemLeftdata"
              :key="index"
              @click="handleJumpNew(index)"
            >
              <div class="itemLeft-title">{{ item.title }}</div>
              <div class="itemLeft-text">{{ item.text }}</div>
              <div class="itemLeft-time">
                <span>{{ item.date }}</span> <span>{{ item.year }}</span>
              </div>
              <div class="itemLeft-jump">
                <div class="arrow-right"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="itemBox">
          <div class="contentList-right">
            <div
              class="itemRight"
              v-for="(item, index) in newItemRightdata"
              :key="index"
              @click="handleClickNew(index)"
            >
              <div class="itemRight-tiem">
                <span>{{ item.date }}</span>
                <span>{{ item.year }}</span>
              </div>
              <div class="itemRight-text" v-html="item.text"></div>
              <div class="itemRight-jump"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="recentActivities" @click="handleDescribeNew">
      <div class="recentActivities-title">近期活动</div>
      <div class="recentActivities-img">
        <img
          src="@/assets/home/dynamic/newdynamic.png"
          alt=""
          height="100%"
          width="100%"
        />
      </div>
      <div class="describe-box">
      <div class="recentActivities-time">
        <span>11</span>
        <span>/</span>
        <span>08</span>
      </div>
      <div class="describe"><span>第五届中国国际进口博览会</span>
      <span class="describe-text">西图盟数字科技创始人丁华受邀发表讲话。</span></div>
    </div>
    </div>
  </div>
  <div class="button-more" v-if="show" @click="handleClickMore">
    <button class="check" >查看更多</button>
  </div>
  <div class="dynamic-more" v-else>
    <div class="dynamic-container">
    <div class="industryTrends">
      <div class="industryTrends-title"></div>
      <div class="industryTrends-contentBoxList">
        <div class="itemBox">
          <div class="contentList-left">
            <div
              class="itemLeft"
              v-for="(item, index) in itemLeftdata"
              :key="index"
              @click="handleJump(index)"
            >
              <div class="itemLeft-title">{{ item.title }}</div>
              <div class="itemLeft-text">{{ item.text }}</div>
              <div class="itemLeft-time">
                <span>{{ item.date }}</span> <span>{{ item.year }}</span>
              </div>
              <div class="itemLeft-jump">
                <div class="arrow-right"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="itemBox">
          <div class="contentList-right">
            <div
              class="itemRight"
              v-for="(item, index) in itemRightdata"
              :key="index"
              @click="handleClick(index)"
            >
              <div class="itemRight-tiem">
                <span>{{ item.date }}</span>
                <span>{{ item.year }}</span>
              </div>
              <div class="itemRight-text" v-html="item.text"></div>
              <!-- <div class="itemRight-text">{{ item.text }}</div> -->
              <div class="itemRight-jump"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="recentActivities" @click="handleDescribe">
      <div class="recentActivities-title"></div>
      <div class="recentActivities-img">
        <img
          src="@/assets/home/dynamic/dynamic.png"
          alt=""
          height="100%"
          width="100%"
        />
      </div>
      <div class="describe-box">
        <div class="recentActivities-time">
        <span>10</span>
        <span>/</span>
        <span>15</span>
      </div>
      <div class="describe"><span>西图盟数字科技召开清华校友研讨会</span></div>
      </div>

    </div>
  </div>
  <div class="button-more">
    <button class="check" @click="handleClickStop">收起信息</button>
  </div>
  </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      newItemLeftdata: [
        {
          title:
            '标杆项目 | 西图盟首个柔性产线技术落地项目获“现场管理成熟度五星级”荣誉',
          text: '西图盟联合实验基地 — 吉利汽车集团中央研究院试制中心总装车间，获中国质量协会的最高级别现场管理评价。',
          date: '27',
          year: '2022.12',
          url: 'https://mp.weixin.qq.com/s?__biz=Mzg2MzcyNTYyMg==&mid=2247484748&idx=1&sn=5c8bbbcfec8fa3a18c5370deedf61cfd&chksm=ce757415f902fd03f0daf0edee9ce0ae864839c6a85bc2dbbd977675842ee7c8a4d8e122c5b1&token=1482181671&lang=zh_CN#rdg',
        },
        {
          title:
            '战略合作 | 西图盟数字科技与滨江区政府签订战略合作协议',
          text: '为中国参与全球数字贸易规则制定提供“浙江方案”助推构建数字贸易时代新秩序，增创国际合作竞争新优势。',
          date: '12',
          year: '2022.12',
          url: 'https://mp.weixin.qq.com/s?__biz=Mzg2MzcyNTYyMg==&mid=2247484722&idx=1&sn=ba8f45315189fcd9323eb06422084d7d&chksm=ce75746bf902fd7d56b3c20ac0941abe7b746c4daff102fa9665d1c899240b3242c764f34a62&token=1482181671&lang=zh_CN#rds',
        },
      ],
      newItemRightdata: [
        {
          date: '12',
          year: '2023.01',
          text: '证书荣誉 | 西图盟数字科技被认定为浙江省科技型中小企业',
          url: 'https://mp.weixin.qq.com/s?__biz=Mzg2MzcyNTYyMg==&mid=2247484775&idx=1&sn=5fdbfa13eb302a8b519e28114c5741bf&chksm=ce75743ef902fd281a822557f10f3720fac3df7ca6b345a1f7aceaf8f407ad5b074a595729e6&token=1482181671&lang=zh_CN#rd',
        },
        {
          date: '27',
          year: '2022.10',
          text: '智造干货 | 超柔性如何止住制造业的“疼”？ <br/> 企业正逐渐走向以消费者为导向的创新型道路，柔性、快速反应正在成为竞争力之一。',
          url: 'https://mp.weixin.qq.com/s?__biz=Mzg2MzcyNTYyMg==&mid=2247484640&idx=1&sn=53a0be9519f4575b034085d92bf727a4&chksm=ce7575b9f902fcafe47a54b6144df8aa552e9d0d6a4e635394929ae805a9ac2ead1b62107fcb&token=1482181671&lang=zh_CN#rd',
        },
        {
          date: '03',
          year: '2022 .11',
          text: '乔迁之喜 | 乔迁典礼上为浙江省数字化设计与制造创新中心揭牌。',
          url: 'https://mp.weixin.qq.com/s?__biz=Mzg2MzcyNTYyMg==&mid=2247484670&idx=1&sn=0e765d5405b23b583cc130e43ad44386&chksm=ce7575a7f902fcb181f99178d6fa1f4759f6a02fdc2bc795c59d7d23800cddc7fd784c95ed89&token=1482181671&lang=zh_CN#rd',
        },
      ],
      itemLeftdata: [
        {
          title:
            '解决方案 | 2022网易数字+大会主论坛分享《数字时代的超柔性产能》',
          text: '我们把以资源为中心的ERP + MES + WMS，进化为以需求为中心的APM + JIS + RAL。',
          date: '27',
          year: '2022.09',
          url: 'https://mp.weixin.qq.com/s/QFNIsFqTUX-RnhErSKq1xw',
        },
        {
          title:
            '项目启动 | 西图盟技术专家组为三锋实业“未来工厂”项目保驾护航！',
          text: '浙江三锋实业股份有限公司创建省级“未来工厂”项目启动会在永康市圆满举行。',
          date: '11',
          year: '2022.08',
          url: 'https://mp.weixin.qq.com/s/toP6X_MGD4Mm3nO5BZF8ug',
        },
      ],
      itemRightdata: [
        {
          date: '26',
          year: '2020.09',
          text: '入会入盟 | 西图盟数字科技获审核通过正式加入浙江省人工智能产业技术联盟',
          url: 'https://mp.weixin.qq.com/s/0G9aEsY33e9PZzkBUVhTgw',
        },
        {
          date: '22',
          year: '2020.08',
          text: '证书荣誉 | 西图盟数字科技通过了软件能力成熟度集成模型V2.13级国际评估认证并正式获得资质证书',
          url: 'https://mp.weixin.qq.com/s/u36t-vavkZolrs1P_qXCwA',
        },
        {
          date: '22',
          year: '2020.07',
          text: '人物专访 | 西图盟孵化业务部王铭洋：用瓷器的骄傲承载烂瓦的野性',
          url: 'https://mp.weixin.qq.com/s/Gg81YkfRZNPw2Ii8BwxHLw',
        },
      ],
      show: true,
    }
  },
  methods: {
    handleJump (index) {
      window.open(this.itemLeftdata[index].url)
    },
    handleClick (index) {
      window.open(this.itemRightdata[index].url)
    },
    handleJumpNew (index) {
      window.open(this.newItemLeftdata[index].url)
    },
    handleClickNew (index) {
      window.open(this.newItemRightdata[index].url)
    },
    handleDescribe () {
      window.open('https://mp.weixin.qq.com/s/9DD7Q8t4pJfGWEztW7FhCw')
    },
    handleDescribeNew () {
      window.open('https://mp.weixin.qq.com/s?__biz=Mzg2MzcyNTYyMg==&mid=2247484683&idx=1&sn=d24d4cdd50e47e2b1abced4285b6b57f&chksm=ce757452f902fd4485b83a57dde89253d92356881b88410796f3d73e5574d281cf5f4b32fda9&token=1482181671&lang=zh_CN#rd')
    },
    handleClickMore () {
      this.show = false
    },
    handleClickStop () {
      this.show = true
    },
  },
}
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
